import { IPersonaDocument, IPersonaPluginData, PersonaType } from "../../../libs/models/Persona";

export interface PersonaState {
    personas: Personas;
    personaTypesLoaded: PersonaType[];
    pinnedPersonas: string[];
    globallyPinnedPersonas: string[];
    personaPlugins: IPersonaPluginData[];
}

export const initialState: PersonaState = {
    personas: {},
    personaTypesLoaded: [],
    pinnedPersonas: [],
    globallyPinnedPersonas: [],
    personaPlugins: [],
};

export type Personas = Record<string, PersonaData>;

export interface PersonaData {
    id: string;
    creatorUserId: string;
    name: string;
    description?: string;
    instructions: string;
    iconName?: string;
    lastUpdatedDate: number;
    lastUsedDate: number;
    type: PersonaType;
    isGloballyPinned: boolean;
    isPinned: boolean;
    pluginNames: string[];
    examplePromptStrings: string[];
    iconColor: string;
    iconBackgroundColor: string;
    documents: IPersonaDocument[];
}

export interface PersonaChange {
    id: string;
    name: string;
    description?: string;
    instructions: string;
    iconName?: string;
    pluginNames: string[];
    examplePromptStrings: string[];
    iconColor: string;
    iconBackgroundColor: string;
    documents: IPersonaDocument[];
}
