import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Link,
    makeStyles,
} from '@fluentui/react-components';
import { useState } from 'react';

const useClasses = makeStyles({
    primary: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    dialogBody: {
        maxHeight: '70vh',
    },
});

export const NewFeatureDialog: React.FC = () => {
    const classes = useClasses();
    const [isOpen, setIsOpen] = useState<boolean>(true);
    return (
        <Dialog open={isOpen}>
            <DialogSurface>
                <DialogBody className={classes.dialogBody}>
                    <DialogTitle>What&apos;s New</DialogTitle>
                    <DialogContent>
                        <hr />
                        <p className={classes.primary}>New features have been added to Eastman Copilot!</p>
                        <p>
                            <b>Bing Search.</b> Eastman Copilot&apos;s innovative Bing Search feature enables seamless
                            interaction with the AI while utilizing Bing&apos;s powerful web search capabilities to find
                            answers to your inquiries. Integrated into the Web Search persona, this feature provides
                            quick and efficient access to a wealth of information across the web, ensuring you receive
                            up-to-date and comprehensive results. You can easily access the Web Search persona at the
                            top of your pinned personas or customize your own persona to include it. Please note that
                            you should avoid sharing Eastman or other sensitive data when using this feature, as such
                            information may be transmitted over the web.
                        </p>
                        <p>
                            <b>Image Uploads.</b> You can now enhance your chats with Eastman Copilot by uploading
                            images in formats like JPG, GIF, and PNG. This new capability allows for enriched
                            conversations, as Eastman Copilot can analyze and discuss the content of these images with
                            you, providing detailed descriptions and insights. This interactive feature transforms how
                            you engage with visual content, making discussions more dynamic and informative.
                        </p>
                        <p>
                            <b>Persona Documents.</b> Tired of repeatedly uploading documents for each chat with a
                            persona? Now, you can upload documents directly to a persona, ensuring that every
                            conversation you have with that persona has seamless access to those documents. This feature
                            streamlines your workflow, saving time and enhancing efficiency.
                        </p>
                        <hr />
                        <p>
                            The Eastman Copilot V2 Quick Reference Guide can be accessed{' '}
                            <Link href={process.env.REACT_APP_COPILOT_QRG_LINK} target="_blank">
                                here
                            </Link>{' '}
                            for more detailed information about Eastman Copilot&apos;s features.
                        </p>
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button
                                appearance="secondary"
                                onClick={() => {
                                    setIsOpen(false);
                                }}
                            >
                                Close
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
