import { IChatMessage } from "./ChatMessage";

export enum PersonaType {
    User,
    Global,
}

export interface IPersona {
    id: string;
    creatorUserId: string;
    name: string;
    description?: string;
    instructions: string;
    iconName?: string;
    lastUpdatedDate: Date;
    lastUsedDate: Date;
    type: PersonaType;
    isGloballyPinned: boolean;
    isPinned: boolean;
    pluginNames: string[];
    examplePromptStrings: string[];
    iconBackgroundColor: string;
    documents: IPersonaDocument[];
}

export interface IEditPersonaData {
    persona: IPersona;
    removedChatDocsSuccess: boolean;
    documentIds: string[];
    messages: IChatMessage[];
}

export interface IPersonaPluginData {
    pluginName: string;
    pluginDisplayName: string;
    pluginDescription: string;
    pluginDescriptionFull: string;
}

export interface IPersonaDocument {
    id: string;
    fileName: string;
    size: string;
    hasChatMemory: boolean;
    blobStorage: boolean;
    isUploaded: boolean;
}
